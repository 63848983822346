import Animated1 from "./assets/animated/1.png";
import Animated2 from "./assets/animated/2.png";
import Animated3 from "./assets/animated/3.png";
import Animated4 from "./assets/animated/4.png";
import Animated5 from "./assets/animated/5.png";

type IntroGroup = {
  title: string;
  desc: string;
  gif: string;
};

type FooterConfig = {
  title: string;
  desc: string;

  app: string;
  forum: string;
  docs: string;

  connect: string;
};

type LangConfig = {
  title: string;
  mysteryEntry: string;
  groups: IntroGroup[];

  brand: string;
  subbrand: string[];
  intro: string;

  footer: FooterConfig;
};

const EN_GROUPS: IntroGroup[] = [
  {
    title: "Create new or use your existing NFTs",
    desc: "Pledge them into the DODO NFT Vault.",
    gif: Animated1,
  },
  {
    title: "Fractionalize the DODO NFT Vault",
    desc:
      "Divide the DODO NFT Vault into pieces and issue fungible tokens representing them.",
    gif: Animated2,
  },
  {
    title: "Create a liquidity pool for the NFT",
    desc:
      "Establish a flexible and efficient market to trade these pieces. Powered by DODO's Proactive Market Maker (PMM) algorithm.",
    gif: Animated3,
  },
  {
    title: "Interconnected liquidity",
    desc:
      "Through DODO's SmartTrade and liquidity aggregation service, the pieces can be traded with any tokens at the best price.",
    gif: Animated4,
  },
  {
    title: "DODO NFT Vault buyout (optional)",
    desc:
      "In some cases, a collector can buy up all the pieces of a DODO NFT Vault and acquire the NFTs in it.",
    gif: Animated5,
  },
];

const ZH_GROUPS: IntroGroup[] = [
  {
    title: "创建或使用你的 NFT",
    desc: "将你的 NFT 质押进 DODO NFT Vault。",
    gif: Animated1,
  },
  {
    title: "将 DODO NFT Vault 碎片化",
    desc: "发行同质化代币，代表 DODO NFT Vault 的每一个碎片。",
    gif: Animated2,
  },
  {
    title: "创建一个流动性池",
    desc: "利用主动做市算法，以最小成本创建一个高效灵活的市场来交易碎片。",
    gif: Animated3,
  },
  {
    title: "享受与任意代币的兑换",
    desc:
      "通过 DODOEX 的智能路由和聚合交易技术，交易碎片时可以得到最好的价格。",
    gif: Animated4,
  },
  {
    title: "买断 DODO NFT Vault（可选）",
    desc: "在某些情况下，某个收藏者可以买断 DODO NFT Vault 并获得其中的 NFT",
    gif: Animated5,
  },
];

const EN: LangConfig = {
  title: "DODO NFT - NFT price discovery and liquidity protocol",
  mysteryEntry: "Fear NFT Boxes on sale Now!",
  brand: "DODO NFT",
  subbrand: [
    "A price discovery and liquidity protocol",
    "for non-standard assets",
  ],
  intro: "HOW DODO NFT WORKS",
  groups: EN_GROUPS,
  footer: {
    title: "DODO NFT IS POWERED BY DODOEX",
    desc: "Learn more about DODO here.",

    app: "Launch APP",
    forum: "Forum",
    docs: "Read the Docs",

    connect: "CONTACT DODO.",
  },
};

const ZH: LangConfig = {
  title: "DODO NFT - 非标准资产的零成本流动性方案",
  mysteryEntry: "Fear NFT卡包火热抢购中",
  brand: "DODO NFT",
  subbrand: ["非标准资产的价格发现和流动性协议"],
  intro: "DODO NFT 如何是工作的？",
  groups: ZH_GROUPS,
  footer: {
    title: "DODO NFT IS POWERED BY DODOEX",
    desc: "Learn more about DODO here.",

    app: "Launch APP",
    forum: "Forum",
    docs: "Read the Docs",

    connect: "CONTACT DODO.",
  },
};

export type LangIdent = "en" | "zh";
export const SELECTOR: { key: LangIdent; disp: string }[] = [
  { key: "en", disp: "EN" },
  { key: "zh", disp: "中" },
];
export const LANGS: Record<LangIdent, LangConfig> = {
  en: EN,
  zh: ZH,
};
