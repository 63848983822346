import { Fragment, FunctionalComponent, createContext, h } from "preact";
import { LANGS, LangIdent, SELECTOR } from "../data";
import { Route, Router } from "preact-router";
import { useEffect, useRef, useState } from "preact/hooks";

import Discord from "../assets/discord.png";
import Discord2x from "../assets/discord@2x.png";
import Logo from "./Logo";
import Reddit from "../assets/reddit.png";
import Reddit2x from "../assets/reddit@2x.png";
import Second from "../assets/second.png";
import Second2x from "../assets/second@2x.png";
import Telegram from "../assets/telegram.png";
import Telegram2x from "../assets/telegram@2x.png";
import Twitter from "../assets/twitter.png";
import Twitter2x from "../assets/twitter@2x.png";
import Wechat from "../assets/wechat.png";
import Wechat2x from "../assets/wechat@2x.png";
import WechatQR from "../assets/dodo-qr.png";
import Enter from "../assets/enter.png";
import clsx from "clsx";
import style from "./App.scss";

const App: FunctionalComponent = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);

  const mainRef = useRef<HTMLElement | null>(null);
  const [docking, setDocking] = useState<"top" | "fixed" | "bottom">("top");

  const sections = useRef<(HTMLElement | null)[]>([]);
  const [active, setActive] = useState(0);
  const [opacities, setOpacities] = useState<number[] | null>(null);

  const [lang, setLang] = useState<LangIdent>("en");
  const lcfg = LANGS[lang];

  useEffect(() => {
    window.scrollTo({ top: 0 });

    const listener = () => {
      if (mainRef.current) {
        const { top, bottom } = mainRef.current.getBoundingClientRect();
        if (top > 0) setDocking("top");
        else if (bottom < window.innerHeight) setDocking("bottom");
        else setDocking("fixed");
      }

      const opacities: number[] = [];
      const actions: number[] = [];
      for (let i = 0; i < lcfg.groups.length; ++i) {
        const section = sections.current[i];
        if (!section) continue;

        const { top, bottom, height } = section.getBoundingClientRect();

        const fullyShown = (window.innerHeight - height) / 2;
        const opacity = Math.max(
          0,
          Math.min(1, (window.innerHeight - bottom) / fullyShown)
        );
        opacities[i] = opacity;

        let action = 0;
        const topDist = top / window.innerHeight;
        const bottomDist = 1 - bottom / window.innerHeight;
        if (bottomDist > 0.75) action = 1;
        if (topDist > 0.75) action = -1;

        actions[i] = action;
      }

      setActive((active) => {
        if (actions[active] === undefined) return active;
        const next = active + actions[active];
        if (next >= 0 && next < lcfg.groups.length) return next;
        return active;
      });
      setOpacities(opacities);
    };

    window.addEventListener("scroll", listener, false);
    return () => window.removeEventListener("scroll", listener, false);
  }, [lcfg]);

  useEffect(() => {
    window.document.title = lcfg.title;
  }, [lcfg]);

  return (
    <div id="app" class={clsx({ [style.loading]: loading })}>
      <div class={style.overlay}>
        <Logo blink={true} />
      </div>

      <nav class={style.nav}>
        <Logo />
        <div class={style.spanner} />
        <a class={style.mysteryEntry} href="https://app.dodonft.io/mystery">
          {lcfg.mysteryEntry} <img src={Enter} />
        </a>
        <div class={style.langs}>
          {SELECTOR.map(({ key, disp }) => (
            <div
              class={clsx(style.lang, {
                [style["lang-selected"]]: key === lang,
              })}
              onClick={() => setLang(key)}
              key={key}
            >
              {disp}
            </div>
          ))}
        </div>
      </nav>

      <div class={style.splash}>
        <div class={style["splash-bg"]} />
        <div class={style.brand}>{lcfg.brand}</div>
        <div class={style.subbrand}>
          {lcfg.subbrand.map((e, idx) => (
            <Fragment>
              <span key={idx} class={style.nobreak}>
                {e}
              </span>
              <br />
            </Fragment>
          ))}
        </div>
        <div class={style.credit}>
          Credit{" "}
          <a
            href="https://twitter.com/TFMStyle"
            rel="noreferrer"
            target="_blank"
          >
            @TFMStyle
          </a>
        </div>
      </div>

      <div class={style.intro}>{lcfg.intro}</div>

      <main class={style.main} ref={mainRef}>
        <div
          class={clsx(style.player, {
            [style["docking-top"]]: docking === "top",
            [style["docking-bottom"]]: docking === "bottom",
            [style["docking-fixed"]]: docking === "fixed",
          })}
        >
          {lcfg.groups.map((group, idx) => (
            <div
              class={clsx(style.layer, {
                [style["active-layer"]]: active === idx,
              })}
            >
              <img src={group.gif} />
            </div>
          ))}
        </div>

        <div class={style.sections}>
          {lcfg.groups.map((group, idx) => {
            const opacity = opacities?.[idx] ?? 0;
            return (
              <section
                class={style.section}
                ref={(r) => (sections.current[idx] = r)}
                style={{
                  opacity,
                }}
              >
                <img class={style["inline-gif"]} src={group.gif} />
                <div class={style["section-right"]}>
                  <div class={style.title}>{group.title}</div>
                  <div class={style.desc}>{group.desc}</div>
                </div>
              </section>
            );
          })}
        </div>
      </main>

      <footer class={style.footer}>
        <div class={style["footer-left"]}>
          <div class={style["footer-title"]}>{lcfg.footer.title}</div>
          <div class={style["footer-desc"]}>{lcfg.footer.desc}</div>
          <div class={style["footer-buttons"]}>
            <a href="https://app.dodonft.io/">
              <button class={style["footer-button"]}>{lcfg.footer.app}</button>
            </a>
            <a href="https://community.dodoex.io/">
              <button class={style["footer-button"]}>
                {lcfg.footer.forum}
              </button>
            </a>
            <a href="https://dodoex.github.io/docs/docs/">
              <button class={style["footer-button"]}>{lcfg.footer.docs}</button>
            </a>
          </div>
        </div>

        <div class={style["footer-right"]}>
          <div class={style["footer-connect-title"]}>{lcfg.footer.connect}</div>

          <div class={style["footer-buttons"]}>
            <a href="https://twitter.com/BreederDodo">
              <img class={style["footer-connect"]} src={Twitter2x} />
            </a>
            <a href="https://medium.com/dodoex">
              <img class={style["footer-connect"]} src={Second2x} />
            </a>
            <a href="https://www.reddit.com/r/DodoEx/">
              <img class={style["footer-connect"]} src={Reddit2x} />
            </a>
            <a href="https://t.me/dodoex_official">
              <img class={style["footer-connect"]} src={Telegram2x} />
            </a>
            <a href="https://discord.com/invite/tyKReUK">
              <img class={style["footer-connect"]} src={Discord2x} />
            </a>
            <img class={style["footer-connect"]} src={Wechat2x} />
            <div class={style["wechat-qr"]}>
              <div class={style["wechat-qr-inner"]}>
                <img src={WechatQR} />
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default App;
