import { FunctionalComponent, h } from "preact";
import style from "./Logo.scss";
import clsx from "clsx";

const Loading: FunctionalComponent<{ blink?: boolean }> = ({
  blink = false,
}) => (
  <svg
    width="36px"
    height="36px"
    viewBox="0 0 36 36"
    version="1.1"
    class={clsx({
      [style.animated]: blink,
    })}
  >
    <g
      id="页面-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="4" fill-rule="nonzero">
        <g transform="translate(1.000000, 0.000000)" id="路径">
          <path
            d="M6.22926094,36 C11.0015083,36 14,32.519774 14,27.9548023 C14,23.3446328 11.0015083,20 6.22926094,20 L0,20 L0,36 L6.22926094,36 Z"
            fill="#FFFFFF"
          ></path>
          <path
            d="M6.22926094,16 C11.0015083,16 14,12.519774 14,7.95480226 C14,3.34463277 11.0015083,0 6.22926094,0 L0,0 L0,16 L6.22926094,16 Z"
            fill="#FFE804"
          ></path>
          <path
            d="M26.021978,16 C30.4175824,16 34,12.6767538 34,7.98899587 C34,3.30123796 30.4615385,0 26.021978,0 C21.6043956,0 18,3.30123796 18,7.98899587 C18,12.6767538 21.6043956,16 26.021978,16 Z"
            fill="#FFFFFF"
          ></path>
          <path
            d="M26.021978,36 C30.4175824,36 34,32.6767538 34,27.9889959 C34,23.301238 30.4615385,20 26.021978,20 C21.6043956,20 18,23.301238 18,27.9889959 C18,32.6767538 21.6043956,36 26.021978,36 Z"
            fill="#FFFFFF"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default Loading;
